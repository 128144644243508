import React from 'react'
import {useTranslation} from 'next-i18next'

type ServicePointDetailsChargerOpeningHoursProps = {
  openingHours: string
}

const ServicePointDetailsChargerOpeningHours = ({
  openingHours,
}: ServicePointDetailsChargerOpeningHoursProps) => {
  const {t} = useTranslation()
  return (
    <div className="border-b border-b-dats-border-gray">
      <div className="bg-dats-s10 p-4 font-rubik text-sm font-bold">
        {t('label_service_point_locator_charging_opening_hours')}
      </div>
      <div className="p-4 text-sm">{openingHours}</div>
    </div>
  )
}

export default ServicePointDetailsChargerOpeningHours
