import type2 from '../public/images/connectors/connector-icon-type2.png'
import type1 from '../public/images/connectors/connector-icon-type1.png'
import type3 from '../public/images/connectors/connector-icon-type3.png'
import css1 from '../public/images/connectors/connector-icon-ccs1.png'
import css2 from '../public/images/connectors/connector-icon-ccs2.png'
import cHAdeMo from '../public/images/connectors/connector-icon-chademo.png'
import schukoEuro from '../public/images/connectors/connector-icon-schuko-euro.png'
import schukoBeFr from '../public/images/connectors/connector-icon-schuko-be-fr.png'
import schukoNlDe from '../public/images/connectors/connector-icon-schuko-nl-de.png'
import {StaticImageData} from 'next/image'

export function getConnectorIcon(connectorType: string): StaticImageData {
  const typeIcon: {[key: string]: StaticImageData} = {
    'IEC-62196-TYPE1': type1,
    'IEC-62196-TYPE2': type2,
    'IEC-62196-TYPE3': type3,
    'COMBO-TYPE1': css1,
    'COMBO-TYPE2': css2,
    CHADEMO: cHAdeMo,
    'CEE-7-4': schukoNlDe,
    'CEE-7-5': schukoBeFr,
    'CEE-7-7': schukoEuro,
  }

  return typeIcon[connectorType]
}
